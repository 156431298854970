const ServerConfig = {
    AWS: {
        //secret: "a/KqSeygKnDIwdjcgKJX+Rqn5/CAbrzJYsIwuo1e",
        //accessKey: "AKIA2VBRBN7HEFUOCMW3",
        //arm-cognito
        //secret: "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",
        //accessKey: "AKIA2VBRBN7HJSDPADFG",
        //cognito-arm2
        secret: "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",
        accessKey: "AKIA2VBRBN7HOVSX6HX3",
        s3Bucket: {
            Region: 'eu-west-1',
            BucketName: 'crm-storage-files-no-worm',
            ApiVersion: '2006-03-01',
            AccessKeyId: 'AKIA2VBRBN7HD5WXPNJP',
            SecretKeyId: 'k7SesmnUNz0GvTh/Z/dUZ54GKQ7ZYG/dZ5873Rg7'
        },
        Cognito: {
            Region: "eu-west-1",
            UserPoolId: "eu-west-1_vaZzKUj4G",
            ClientId: "3ins7cv4qnsfpjep2akfdnd20v",
        }
    },
    authTimeout: 18000,
    platformName: 'Lead4Life',
}

export { ServerConfig };